let image = document.querySelectorAll('.block_content_image_switch .image');

document.addEventListener('DOMContentLoaded', function () {
    image.forEach(function (el) {
        let pauseButton = el.querySelector('.pause-button');

        if (pauseButton) {
            let pauseOffset = (el.offsetWidth / 2) - 36;
            pauseButton.style.transformOrigin = 50 + '% -' + pauseOffset + 'px';

            window.addEventListener('resize', function () {
                let pauseOffset = (el.offsetWidth / 2) - 36;
                pauseButton.style.transformOrigin = 50 + '% -' + pauseOffset + 'px';
            });
        }
    });
});
